<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <tabel-data :tahun_anggaran="tahun_anggaran" :items=items @rekap_pagu_anggaran=rekap_pagu_anggaran></tabel-data>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs, BCard, BCol, BRow,
  BTab
} from 'bootstrap-vue'
import TabelData from './component/tabelData.vue'
export default {
  components: {
    BTabs,
    BTab, BCard, BCol, BRow,
    TabelData,
  },
  props: {

  },
  data() {
    return {
      tahun_anggaran: [],
      pagu_anggaran: [],
      items: []

    }
  },
  mixins: [Base],
  mounted() {
    this.load_tahun_anggaran();

  },
  methods: {
    async load_tahun_anggaran() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/laporan/tahun_anggaran',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.tahun_anggaran = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async rekap_pagu_anggaran(val) {
      const self = this;
      await axios({
        method: 'PUT',
        url: 'api/pu/laporan/rekap_pagu_anggaran',
        data: {
          th: val.th
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.items = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>